import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    background: {
      default: '#EEEAEA',
      paper: '#fff',
    },
    primary: {
      light: '#FF7549',
      main: '#FF7549',
      dark: '#FF7549',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    text: {
      primary: '#323232',
      secondary: '#6E6E6E',
      disabled: '#B9B9B9',
    },
  },
  typography: {
    // fontSize: 16,
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 600,
    },
    h6: {
      color: '#323232',
      fontWeight: 600,
      fontSize: '1.1rem',
      lineHeight: '1.5rem',
      letterSpacing: 0,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.variant === 'outlined' && {
            borderColor: '#FF7549',
            color: '#FF7549',
          }),
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {fontsize: '.85rem'},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '.9rem',
        },
      },
    },
  },
});
