import {useTheme} from '@mui/material/styles';
import {createContext, Dispatch, FC, useContext, useEffect, useReducer, useState} from 'react';
interface LayoutDispatchState {
  isSidebarOpened: boolean;
}
interface LayoutState extends LayoutDispatchState {
  isPermanent: boolean;
  //isOpen: boolean;
}

enum LayoutTypes {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
}
type ToggleSidebarAction = {type: typeof LayoutTypes.TOGGLE_SIDEBAR};
type LayoutActions = ToggleSidebarAction;
const LayoutStateContext = createContext<LayoutState>({
  isSidebarOpened: false,
  isPermanent: true,
  //isOpen: true,
} as LayoutState);
LayoutStateContext.displayName = 'LayoutContext';
// eslint-disable-next-line @typescript-eslint/no-empty-function
const LayoutDispatchContext = createContext<Dispatch<LayoutActions>>(() => {});

const layoutReducer = (state: LayoutDispatchState, action: LayoutActions): LayoutDispatchState => {
  switch (action.type) {
    case LayoutTypes.TOGGLE_SIDEBAR:
      return {...state, isSidebarOpened: !state.isSidebarOpened};
    default: {
      return state;
    }
  }
};

const LayoutProvider: FC = ({children}) => {
  const theme = useTheme();
  const [isPermanent, setIsPermanent] = useState(true);
  const [state, dispatch] = useReducer(layoutReducer, {
    isSidebarOpened: false,
  });

  useEffect(() => {
    function handleWindowWidthChange() {
      const windowWidth = window.innerWidth;
      const breakpointWidth = theme.breakpoints.values.md;
      const isSmallScreen = windowWidth < breakpointWidth;

      if (isSmallScreen && isPermanent) {
        setIsPermanent(false);
      } else if (!isSmallScreen && !isPermanent) {
        setIsPermanent(true);
      }
    }
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();

    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  }, [isPermanent, theme.breakpoints.values.md]);

  const value = {...state, isPermanent};

  return (
    <LayoutStateContext.Provider value={value}>
      <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
};

const useLayoutState = () => {
  const context = useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
};

const useLayoutDispatch = () => {
  const context = useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider');
  }
  return context;
};

const toggleSidebar = (dispatch: any): LayoutActions =>
  dispatch({
    type: LayoutTypes.TOGGLE_SIDEBAR,
  });

export {LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, LayoutStateContext};
