import {FormControl, FormLabel, InputAdornment, TextField} from '@mui/material';

export default function TextFieldCustom(props: any) {
  return (
    <FormControl fullWidth={props.fullWidth}>
      {props.label && (
        <FormLabel id={`${props.id}-label`} sx={{mb: 1, fontWeight: 'bold', color: '#323232'}}>
          {props.label}
          {props.required && <span>*</span>}
        </FormLabel>
      )}
      <TextField
        fullWidth={props.fullWidth}
        size={props.size || 'medium'}
        id={props.id}
        type={props.type}
        required={props.required}
        sx={{width: props.type === 'number' ? '100px' : 'auto'}}
        inputProps={{
          ...props.inputProps,
          maxLength: props.maxLength ?? 255,
        }}
        InputProps={{
          endAdornment: props.isPercent && <InputAdornment position="end">%</InputAdornment>,
        }}
        rows={props.rows}
        helperText={props.helperText}
        value={props.answers[props?.id] ?? ''}
        onChange={(e: any) => {
          const k: string = props?.id;
          props.setAnswers({
            ...props.answers,
            [k]: props.type === 'number' ? parseInt(e.target?.value) : e.target?.value,
          });
        }}
        multiline={props.type === 'longText'}
      />
    </FormControl>
  );
}
