import {FormControl, FormLabel} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {SEARCH_API_BASE_URL} from 'config';
import {acquireApiToken} from 'lib/auth';
import throttle from 'lodash/throttle';
import { getSurveysList } from './api';
import * as React from 'react';

const data = {select: '*', top: 50, count: true, skip: 0};

const headers = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY || '',
};

type Tenant = {
  id: string;
  tenantcode: string;
  sqft: string;
  tenantname: string;
  propertycode: string;
  propertyid: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
};

type Props = {
  answers: any;
  label?: string;
  id: string;
  survey: any,
  setAnswers: React.Dispatch<any>;
  required?: boolean;
  value: Tenant | null;
  setValue(newValue: Tenant | null): void;
};

const TenantAutoComplete = ({answers, id, label, required, setAnswers, setValue, value, survey}: Props) => {
  const [inputValue, setInputValue] = React.useState('');
  const [lastSurvey, setLastSurvey] = React.useState('')
  const [options, setOptions] = React.useState<readonly Tenant[]>([]);
  React.useEffect(() => {
    !!setAnswers &&
      !!answers &&
      setAnswers({
        ...answers,
        PropertyId: value?.propertycode,
        PropertyAddress: value?.address1
          ? `${value?.address1} \n ${value?.address2} \n ${value?.city} ${value?.state}, ${value?.zipcode}`
          : '',
        Tenant: value?.tenantname,
        TenantCode: value?.tenantcode || '',
        Sqft:  value?.sqft ==='-NA-' ? 0 : value?.sqft
      });
  }, [value]);

  const callSurveyList = async (newValue: any) => {
      const data = await getSurveysList(survey.sharepointListId, newValue.tenantcode)
      setLastSurvey('');
      if(data && data.value.length) {
        setLastSurvey(data?.value[0].lastModifiedDateTime)
      }
  }

  const search = React.useMemo(
    () =>
      throttle((input: string, callback: (results?: readonly Tenant[]) => void) => {
        acquireApiToken().then((token) => {
          return fetch(SEARCH_API_BASE_URL, {
            body: JSON.stringify({
              ...data,
              search: input + '*',
              filter: '',
            }),
            method: 'POST',
            headers: {...headers, Authorization: `Bearer ${token}`},
            mode: 'cors',
          })
            .then((response) => response.json())
            .then((data) => {
              callback(
                (data.value as any[]).map((x) => ({
                  ...x,
                })) as Tenant[]
              );
            });
        });
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    search(inputValue, (results?: readonly Tenant[]) => {
      if (active) {
        let newOptions: readonly Tenant[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <>
    <FormControl fullWidth>
      {label && (
        <FormLabel id={`${id}-label`} sx={{mb: 1, fontWeight: 'bold', color: '#323232'}}>
          {label}
          {required && '*'}
        </FormLabel>
      )}
      <Autocomplete
        sx={{width: 'auto'}}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : `${option.tenantname} - (${option.tenantcode}) - Total Sqft: ${option.sqft == '0' ? '-NA-' : new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(Number(option.sqft))}`.trim()
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        noOptionsText={inputValue ? 'No Results' : ''}
        aria-required={required}
        filterSelectedOptions
        value={value}
        onChange={(event: any, newValue: Tenant | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          callSurveyList(newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            fullWidth
            autoComplete="off"
            required={required}
            placeholder="Search by tenant name or address"
            InputLabelProps={{required: required}}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.tenantcode} style={{display: 'block'}}>
              <div>
                <Grid container alignItems="center">
                  {/* <Grid item>
                    <Box component={LocationOnIcon} sx={{color: 'text.secondary', mr: 2}} />
                  </Grid> */}
                  <Grid item xs>
                    {option.tenantcode && (
                      <Typography variant="body1" color="text.primary">
                        {option.tenantname} ({option.tenantcode}) - <b> Total Sqft: {option.sqft == '0' ? '-NA-' : new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(Number(option.sqft))} </b>
                      </Typography>
                    )}
                    <div>
                      <Typography variant="body2" color="text.secondary">
                        {option.address1}
                        <br />
                        {option.address2}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body2">
                        {option.city}, {option.state} {option.zipcode}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </li>
          );
        }}
      />
    </FormControl>
    {lastSurvey !== '' && <span style={{'color':'red'}}>Last surveyed on {new Date(lastSurvey).toDateString()}</span>}
    </>
  );
};

export default TenantAutoComplete;
