import {Box} from '@mui/material';
import {login} from 'lib/auth';
import {useEffect} from 'react';

const Login = () => {
  useEffect(() => {
    login();
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignContent="center">
      <Box display="flex" alignContent="center" flexDirection="column">
        <Box mt={3} mb={3} justifyContent="center" display="flex"></Box>
      </Box>
    </Box>
  );
};

export default Login;
