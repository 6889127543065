import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';
import PageRender from './PageRender';
import Survey from './Survey';
import {SurveyType} from './SurveyType';
export type SurveyStep = {
  label: string;
  optional: boolean;
  content?: React.ReactNode;
  questions?: any[];
};

type SurveyPageProps = {
  survey: SurveyType;
};

const SurveyPage = ({survey}: SurveyPageProps) => {
  const [answers, setAnswers] = useState(survey?.results ?? {});
  const [localAnswers, setLocalAnswers] = useState({});
  const steps: SurveyStep[] =
    survey?.pages?.map((x) => {
      return {
        ...x,
        content: (
          <PageRender
            answers={answers}
            localAnswers={localAnswers}
            setLocalAnswers={setLocalAnswers}
            setAnswers={setAnswers}
            survey={survey}
            questions={x?.questions || []}
          />
        ),
      };
    }) || [];

  return isMobile ? (
    // <MobileSurvey steps={steps} survey={survey} answers={answers} />
    <Survey steps={steps} survey={survey} answers={answers} />
  ) : (
    <Survey steps={steps} survey={survey} answers={answers} />
  );
};

export default SurveyPage;
