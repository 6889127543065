class Logging {
  private static instance: Logging;

  public initialize() {
    console.log('');
  }
  public static getInstance(): Logging {
    if (!Logging.instance) {
      Logging.instance = new Logging();
    }
    return Logging.instance;
  }
}
const Logger = Logging.getInstance();
export default Logger;
