import {SurveyType} from './SurveyType';

const sharepointListIds = {
  dev: {1: 'c635ac90-bdc9-4569-ab13-4863e43ac683'},
  test: {1: '6417162c-f1eb-45df-b0e8-0a8a334fca5b'},
  uat: {1: '9c8f94c6-37d5-4e5c-9f15-49e3304ec797'},
  prod: {1: 'bfa28f68-ebcc-4d8b-9a07-e2bb03a9408f'},
};
type envType = keyof typeof sharepointListIds;

export const allSurveys: SurveyType[] = [
  {
    id: 1,
    name: 'customer',
    subTitle: '',
    title: 'Customer Engagement Survey',
    completeHtml: 'Thank you for completion of this survey',
    sharepointListId: sharepointListIds[(process.env.REACT_APP_ENV || 'dev') as envType][1],
    showQuestionNumbers: true,
    showPageNumbers: true,
    results: {
      RespondentsRole: [],
      P1Q2: [],
      P2Q3: [],
      P3Q4: [],
      P3Q5: [],
      P3Q6: [],
      P3Q7: [],
      P6Q2: [],
      'RespondentsRole@odata.type': 'Collection(Edm.String)',
      'P1Q2@odata.type': 'Collection(Edm.String)',
      'P2Q3@odata.type': 'Collection(Edm.String)',
      'P3Q4@odata.type': 'Collection(Edm.String)',
      'P3Q5@odata.type': 'Collection(Edm.String)',
      'P3Q6@odata.type': 'Collection(Edm.String)',
      'P3Q7@odata.type': 'Collection(Edm.String)',
      'P6Q2@odata.type': 'Collection(Edm.String)',
    },
    pages: [
      {
        id: 0,
        label: 'Property',
        optional: false,
        questions: [
          {
            id: 'Property',
            type: 'TenantSelect',
            questionText: 'Property',
            required: false,
          },
          {
            id: 'P1Q4',
            type: 'shortText',
            charLimit: 255,
            questionText: 'Tenant Representative Name',
            required: true,
          },
          {
            id: 'P1Q5',
            gridItemProps: {xs: 12, md: 6},
            type: 'shortText',
            inputType: 'email',
            charLimit: 255,
            questionText: 'Email',
          },
          {
            id: 'P1Q6',
            gridItemProps: {xs: 12, md: 6},
            type: 'shortText',
            inputType: 'tel',
            charLimit: 25,
            questionText: 'Phone Number',
          },
          {
            id: 'RespondentsRole',
            questionText: 'Respondents Role',
            type: 'multiChoice',
            inline: true,
            choices: [
              {key: 'Accounting', value: 'Accounting'},
              {key: 'Administrative Support', value: 'Administrative Support'},
              {key: 'Business Decision Maker', value: 'Business Decision Maker'},
              {key: 'Lease Administration', value: 'Lease Administration'},
              {
                key: 'Regional Facilities Manager',
                value: 'Regional Facilities Manager',
              },
              {key: 'Warehouse Manager', value: 'Warehouse Manager'},
            ],
          },
          {
            id: 'P1Q2',
            questionText: 'Customer Use',
            type: 'multiChoice',
            inline: true,
            required: true,
            choices: [
              {key: 'Construction Services', value: 'Construction Services'},
              {key: 'Distribution B2B', value: 'Distribution B2B'},
              {key: 'Distribution Retail (E-Commerce)', value: 'Distribution Retail (E-Commerce)'},
              {key: 'Government', value: 'Government'},
              {key: 'Office', value: 'Office'},
              {
                key: 'Retail Operations',
                value: 'Retail Operations',
              },
              {key: 'Manufacturing', value: 'Manufacturing'},
              {key: 'Transportation/Freight', value: 'Transportation/Freight'},
              {key: 'Other', value: 'Other'},
            ],
          },
          {
            id: 'P1Q2Other',
            gridStyle: {paddingTop: 0},
            questionText: 'Other',
            isVisible: (answers: any) => answers.P1Q2.includes('Other'),
            type: 'shortText',
            charLimit: 255,
          },
          {
            id: 'P2Q1',
            type: 'singleChoice',
            questionText: 'How is your business trending compared to the last year?',
            inline: true,
            choices: [
              {key: 'Much Stronger', value: 'Much Stronger'},
              {key: 'Stronger', value: 'Stronger'},
              {key: 'About the Same', value: 'About the Same'},
              {key: 'Weaker', value: 'Weaker'},
              {key: 'Much Weaker', value: 'Much Weaker'},
            ],
          },
          {
            id: 'P3Q1',
            gridItemProps: {xs: 8, sm: 8, md: 5},
            fullWidth: false,
            questionText: 'How much of your space are you utilizing?',
            type: 'shortText',
            inputType: 'number',
            isPercent: true,
            charLimit: 255,
          },
          {
            id: 'Q3Part2',
            gridItemProps: {xs: 4, sm: 4, md: 7},
            questionText: 'Need more space?',
            inline: true,
            inputLabel: ' ',
            type: 'YesNoCheckbox',
          },
          {
            id: 'P1Q3',
            type: 'shortText',
            inputType: 'number',
            charLimit: 255,
            questionText: 'How many employees are currently working in your building?',
            fullWidth: false,
          },
          {
            id: 'P3Q6',
            type: 'multiChoice',
            questionText: 'What fit out does the customer have? (Observation – no need to ask) ',
            inline: true,
            choices: [
              {key: 'Floor stack', value: 'Floor stack'},
              {key: 'Racked', value: 'Racked'},
              {key: 'Autonomous', value: 'Autonomous'},
              {key: 'Manufacturing', value: 'Manufacturing'},
              {key: 'Light Assembly', value: 'Light Assembly'},
              {key: 'Other', value: 'Other'},
            ],
          },
          {
            id: 'P3Q6Other',
            isVisible: (answers: any) => answers.P3Q6.includes('Other'),
            type: 'shortText',
            charLimit: 255,
          },
          {
            id: 'P3Q7',
            type: 'multiChoice',
            questionText: 'Is this customer a candidate for: (Observation – no need to ask)',
            inline: true,
            choices: [
              {key: 'LED Upgrade', value: 'LED Upgrade'},
              {key: 'Energy IQ', value: 'Energy IQ'},
            ],
          },
          {
            id: 'P3Q2',
            questionText:
              'Is the size, location, layout and characteristics of your space conducive for your current business?',
            inline: true,
            type: 'YesNo',
          },
          {
            id: 'P3Q3',
            type: 'singleChoice',
            isVisible: (answers: any) => answers.P3Q2 === false,
            questionText: 'Size:',
            gridItemProps: {xs: 12, md: 3},
            choices: [
              {key: 'Too Big', value: 'Too Big'},
              {key: 'Too Small', value: 'Too Small'},
            ],
          },
          {
            id: 'P3Q4',
            type: 'multiChoice',
            isVisible: (answers: any) => answers.P3Q2 === false,
            questionText: 'Location:',
            gridItemProps: {xs: 12, md: 4},
            choices: [
              {key: 'Need More Locations', value: 'Need More Locations'},
              {key: 'Need to Relocate', value: 'Need to Relocate'},
            ],
          },
          {
            id: 'P3Q5',
            type: 'multiChoice',
            isVisible: (answers: any) => answers.P3Q2 === false,
            questionText: 'Layout:',
            gridItemProps: {xs: 12, md: 5},
            choices: [
              {key: 'Interior Alteration Needs', value: 'Interior Alteration Needs'},
              {key: 'Exterior Alteration Needs', value: 'Exterior Alteration Needs'},
              {key: 'Relo potential', value: 'Relo potential'},
              {
                key: 'Equipment (HVAC/Dock Doors/Ramps/Lighting)',
                value: 'Equipment (HVAC/Dock Doors/Ramps/Lighting)',
              },
              {key: 'Other', value: 'Other'},
            ],
          },
          {
            id: 'P3Q5Other',
            isVisible: (answers: any) => answers.P3Q5.includes('Other'),
            type: 'shortText',
            charLimit: 255,
          },
          {
            id: 'P2Q3',
            type: 'multiChoice',
            questionText:
              'Are there ways that Link can assist with the operation of your business?',
            choices: [
              {key: 'Operational', value: 'Operational', id: 'P2Q3Operational'},
              {key: 'Leasing', value: 'Leasing', id: 'P2Q3Leasing'},
              {
                key: 'Construction',
                value: 'Construction',
                id: 'P2Q3Construction',
              },
              {
                key: 'Accounting/Finance',
                value: 'Accounting/Finance',
                id: 'P2Q3Accounting',
              },
              {key: 'Other', value: 'Other', id: 'P2Q3Other'},
            ],
          },
          {
            id: 'P6Q2',
            type: 'multiChoice',
            questionText:
              'Does your company have any requirements or goals around any of the following sustainability or corporate responsibility areas?',
            gridItemProps: {xs: 12},
            inline: true,
            choices: [
              {
                key: 'Annual Reporting (GRESB, Energy Star)',
                value: 'Annual Reporting (GRESB, Energy Star)',
              },
              {
                key: 'Overarching Community Goals/Sustainability',
                value: 'Overarching Community Goals/Sustainability',
              },
              {key: 'DE&I or Supplier Diversity', value: 'DE&I or Supplier Diversity'},
            ],
          },
          {
            id: 'P6Q3',
            type: 'longText',
            inputType: 'longText',
            questionText: 'Other:',
            InputLabelProps: {shrink: true},
            rows: 5,
          },
        ],
      },
    ],
  },
];
