import Logger from 'lib/logging';
import AppProvider from 'providers/AppProvider';

Logger.initialize();

function App() {
  return <AppProvider />;
}

export default App;
