import {AppBar, Avatar, Button, Menu, MenuItem, Tooltip} from '@mui/material';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import {useAuthContext} from 'common/context/AuthContext';
import {logout} from 'lib/auth';
import {useState} from 'react';

const stringToHex = (text: string) => {
  let hash = 0;
  if (text.length === 0) return '#CCCCCC';
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

const Header = () => {
  const theme = useTheme();

  const {
    state: {user},
  } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return (
    <Box>
      <AppBar
        color="inherit"
        sx={{zIndex: theme.zIndex.drawer + 1, backgroundColor: '#252429'}}
        position="relative"
      >
        <Toolbar disableGutters sx={{alignItems: 'center'}}>
          <div style={{width: '64px'}}></div>
          {/* <img 
            src={logo}
            onClick={() => {
              navigate('/');
            }}
            height={'26px'}
            width={'auto'}
          /> */}
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            sx={{
              color: '#DEC7AC',
              fontSize: '20px',
              textTransform: 'uppercase',
              lineHeight: '25px',
            }}
          >
            <span>Survey</span>
          </Box>
          {!!user && (
            <Tooltip title={user?.name ?? ''} aria-label={user?.name ?? ''}>
              <Button aria-haspopup="true" onClick={handleOpenMenuClick}>
                <Avatar
                  alt={user?.name}
                  src={''}
                  style={{
                    backgroundColor: stringToHex(user?.name ?? '#000'),
                    color: theme.palette.getContrastText(stringToHex(user?.name ?? '#000')),
                  }}
                >{`${user?.name.split(' ')[0]?.charAt(0)}${user?.name
                  .split(' ')[1]
                  ?.charAt(0)}`}</Avatar>
              </Button>
            </Tooltip>
          )}

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
