import {acquireSilentToken} from 'lib/auth';

export const saveSurvey = async (
  sharepointListId: string,
  answers: any,
  name: string,
  email: string
) => {
  const token = await acquireSilentToken();
  const headers = {'Content-Type': 'application/json', Authorization: `Bearer ${token}`};
  const url = `https://graph.microsoft.com/v1.0/sites/${process.env.REACT_APP_SHAREPOINT_SITE_ID}/lists/${sharepointListId}/items`;

  const body = {
    fields: {
      Email: email,
      Name: name,
      Title: email,
      Date: new Date().toISOString(),
      ...answers,
    },
  };

  try {
    const json = await fetch(url, {body: JSON.stringify(body), method: 'POST', headers}).then(
      (response) => response.json()
    );
    if (json.error) {
      return false;
    }
    return true;
  } catch (error) {
    console.error(error);
    //TODO: handle error
    return false;
  }
};
export const getSurveysList = async (
  sharepointListId: string,
  tenantcode: string
) => {
  const token = await acquireSilentToken();
  const headers = {'Content-Type': 'application/json', Authorization: `Bearer ${token}`};
  const url = `https://graph.microsoft.com/v1.0/sites/${process.env.REACT_APP_SHAREPOINT_SITE_ID}/lists/${sharepointListId}/items?`+ `expand=fields(select=TenantCode)&filter=fields/TenantCode eq` + `'`+tenantcode+`'`;


  try {
    const json = await fetch(url, { method: 'GET', headers}).then(
      (response) => response.json()
    );
    if (json.error) {
      return false;
    }
    return json
  } catch (error) {
    console.error(error);
    //TODO: handle error
    return false;
  }
};

