import {Button, Container} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const PageNotFound = (props: any) => {
  const navigate = useNavigate();
  return (
    <Container>
      <p>
        <strong>
          <i>Page Not Found</i>
        </strong>
      </p>
      <Button variant="outlined" size="small" onClick={() => navigate(-1)}>
        back
      </Button>
    </Container>
  );
};

export default PageNotFound;
