import {styled} from '@mui/material/styles';
import AppRouter from 'router/AppRouter';
import Header from './Header';

const PREFIX = 'MyRoot';
const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  [`& .${classes.main}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    flexGrow: 1,
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} 0 0 0`,
    },
  },
}));

const Layout = () => {
  return (
    <Root className={classes.root}>
      <Header />
      <main className={classes.main}>
        <AppRouter />
        {/* <Offline>You offline</Offline>
        <Online>You Online</Online> */}
      </main>
    </Root>
  );
};

export default Layout;
