import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Paper, Stack, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import {useAuthContext} from 'common/context/AuthContext';
import {useSnackbar} from 'notistack';
import React, {useState} from 'react';
import {WIZARD_STEP_NEXT_TEXT, WIZARD_STEP_PREVIOUS_TEXT} from '.';
import {saveSurvey} from './api';
import SurveyFinishedMessage from './SurveyFinishedMessage';
import SurveyHeader from './SurveyHeader';
import {SurveyStep} from './SurveyPage';
import {SurveyType} from './SurveyType';
type Props = {steps: SurveyStep[]; survey: SurveyType; answers: any};

const Survey = ({steps, survey, answers}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isFinished = activeStep === steps.length;
  const [isSaving, setIsSaving] = useState(false);
  const {
    state: {user},
  } = useAuthContext();
  const formName = `frm${activeStep}`;
  const isSinglePage = steps.length === 1;
  const handleNext = async () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    const isFinished = activeStep === steps.length - 1;

    if (isFinished) {
      const success = await handleSave();
      if (success) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        console.log('error saving');
      }
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const isSuccess = await saveSurvey(
      survey.sharepointListId,
      answers,
      user?.name || '',
      user?.username || ''
    );

    enqueueSnackbar(
      isSuccess ? 'Feedback Received!' : 'There was an error submitting your survey',
      {variant: isSuccess ? 'success' : 'error'}
    );
    setIsSaving(false);
    return isSuccess;
  };

  if (isFinished) {
    return <SurveyFinishedMessage survey={survey} />;
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        my: 0,
        mx: 'auto',
        p: {xs: 1, md: 2},
        maxWidth: '1080px',
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <SurveyHeader title={survey.title} subTitle={survey.subTitle} />
      {!isSinglePage && (
        <Stepper activeStep={activeStep} sx={{paddingY: 2, mx: {xs: -2, md: -3}}} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps: {completed?: boolean} = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (label.optional) {
              labelProps.optional = (
                <Typography variant="caption" align="center">
                  Optional
                </Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps} sx={{textAlign: 'center'}}>
                  {label.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      <Stack direction={'column'} sx={{overflow: 'hidden', flex: 1}} spacing={2}>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography variant="h6" sx={{m: 2}} align="center">
              {survey.completeHtml || 'Thank you for your feedback!'}
            </Typography>
          </React.Fragment>
        ) : (
          <>
            <Typography variant="body1" px={3}>
              Required*
            </Typography>
            <Box sx={{overflow: 'auto', flex: 1}} px={3}>
              <form
                id={formName}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNext();
                }}
              >
                {steps[activeStep].content}
              </form>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                py: 1,
                px: isSinglePage ? 0 : 3,
                borderTop: '1px solid #ccc',
              }}
            >
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  {WIZARD_STEP_PREVIOUS_TEXT}
                </Button>
              )}
              <Box sx={{flex: '1 1 auto'}} />

              <LoadingButton
                variant="contained"
                size="large"
                form={formName}
                type="submit"
                endIcon={activeStep < steps.length - 1 && <ArrowForwardIcon />}
                loading={isSaving}
              >
                {activeStep === steps.length - 1 ? 'Finish' : WIZARD_STEP_NEXT_TEXT}
              </LoadingButton>
            </Box>
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default Survey;
