import {Button, Snackbar} from '@mui/material';
import React, {FC, useEffect} from 'react';
import * as serviceWorker from '../serviceWorkerRegistration';

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);
  const reloadPage = () => {
    waitingWorker?.postMessage({type: 'SKIP_WAITING'});
    setShowReload(false);
    window.location.reload();
  };

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
    //reloadPage();
  };

  useEffect(() => {
    serviceWorker.register({onUpdate: onSWUpdate});
  }, []);

  return (
    <Snackbar
      open={showReload}
      message="A new version is available!"
      onClick={reloadPage}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          Reload
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
