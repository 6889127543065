import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
export default function YesNoCheckbox(props: any) {
  const labelId = `$yesnocheckbox-label-{props.id}`;
  const id = props.id;
  const checked = props.answers[id] ?? false;
  const label = props?.inputLabel ?? '';
  return (
    <FormControl>
      <FormLabel id={labelId} focused={false} sx={{fontWeight: 'bold', color: '#323232'}}>
        {props.questionText}
        {props.required && <span>*</span>}
      </FormLabel>
      <FormControlLabel
        value={false}
        control={<Checkbox required={props.required} checked={checked} />}
        label={label}
        onChange={(event: any) => {
          const k: string = props?.id;
          props.setAnswers((answers: any) => ({...answers, [k]: event?.target?.checked}));
        }}
      />
    </FormControl>
  );
}
