import SurveyManager from 'components/survey/Manager';
import SurveyLanding from 'components/survey/SurveyLanding';
import PageNotFound from 'components/Unknown/PageNotFound';
import {RouteObject} from 'react-router-dom';
import PrivateRoute from 'router/PrivateRouter';

export const RoutesConfig: RouteObject[] = [
  {
    element: (
      <PrivateRoute>
        <SurveyManager />
      </PrivateRoute>
    ),
    path: '/',
    children: [
      {
        element: (
          <PrivateRoute>
            <SurveyLanding />
          </PrivateRoute>
        ),
        path: ':id',
        children: [],
      },
    ],
  },
  {
    element: (
      <PrivateRoute>
        <PageNotFound />
      </PrivateRoute>
    ),
    path: '*',
    children: [],
  },
];
