import {Outlet, useLocation} from 'react-router';
import {allSurveys} from './surveydata';
import Surveys from './Surveys';

const SurveyManager = (props: any) => {
  const location = useLocation();
  return (
    // <Box style={{overflowY: 'auto', overflowX: 'hidden', height: '100%'}}>
    <>
      {location.pathname === '/' ? <Surveys surveyList={allSurveys} /> : null}
      <Outlet />
    </>
  );
};

export default SurveyManager;
