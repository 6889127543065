import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {LayoutProvider} from 'common/context/LayoutContext';
import Login from 'components/auth/Login';
import ServiceWorkerWrapper from 'components/ServiceWorkerWrapper';
import {theme} from 'config/theme';
import Layout from 'layout/Layout';
import {AuthenticatedTemplate, AuthWrapper, UnauthenticatedTemplate} from 'lib/auth';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';

const AppProvider = () => {
  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <SnackbarProvider
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        maxSnack={3}
        autoHideDuration={5000}
        preventDuplicate
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <LayoutProvider>
                <AuthWrapper>
                  <AuthenticatedTemplate>
                    <Layout />
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <Login />
                  </UnauthenticatedTemplate>
                </AuthWrapper>
              </LayoutProvider>
            </BrowserRouter>
            <ServiceWorkerWrapper />
          </ThemeProvider>
        </StyledEngineProvider>
      </SnackbarProvider>
    </React.Suspense>
  );
};

export default AppProvider;
