import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Button, Container, Paper, Stack, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Box} from '@mui/system';
import {useNavigate} from 'react-router-dom';
import {SurveyType} from './SurveyType';
type Props = {surveyList: SurveyType[]};

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Surveys = ({surveyList}: Props) => {
  const navigate = useNavigate();
  const handleClick = (id: string) => {
    navigate(`/${id}`);
  };
  return (
    <Container maxWidth="md">
      <h1>Surveys</h1>

      {surveyList.map((s) => {
        return (
          <Item key={s.id}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h6"> {s.title}</Typography>
              </Box>
              <Button
                key={s.id}
                variant="contained"
                size="medium"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  handleClick(s.name || s.id.toString());
                }}
              >
                Start Survey
              </Button>
            </Stack>
          </Item>
        );
      })}
    </Container>
  );
};

export default Surveys;
