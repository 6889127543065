import {useIsAuthenticated} from '@azure/msal-react';
import {Navigate} from 'react-router';
type Props = {children: JSX.Element};

const PrivateRoute = ({children}: Props) => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? children : <Navigate to="/" />;
};

export default PrivateRoute;
