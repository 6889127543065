import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import * as React from 'react';
import TextFieldCustom from './TextFieldCustom';

export default function CheckboxGroup(props: any) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const k: string = event.target.name;
    const v: boolean = event?.target?.checked;

    let answers: string[] = [];
    if (v) {
      answers = [...new Set([...props.answers[props.id], k])];
    } else {
      answers = [...props.answers[props.id]];
      answers = answers.filter(function (item) {
        return item !== k;
      });
    }

    const v1: string[] = [...answers];
    const k1: string = props.id;

    props.setAnswers({...props.answers, [k1]: v1});
  };

  return (
    <Box sx={{display: 'flex'}}>
      <FormControl component="fieldset" variant="standard" focused={false}>
        <FormLabel component="legend" sx={{fontWeight: 'bold', color: '#323232'}}>
          {props.questionText}
          {props.required && ' *'}
        </FormLabel>
        <FormGroup row={props.inline}>
          {props.choices.map((x: any, i: number) => {
            const checked: boolean = props.answers[props.id]?.includes(x.value);
            const hasSubAnswer: boolean = x.id && checked;
            return (
              <Box
                key={i}
                sx={{display: 'flex', alignContent: 'center', mb: hasSubAnswer ? 2 : 0}}
                width={hasSubAnswer ? '600px' : 'auto'}
              >
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      required={props.required && props.answers[props.id].length === 0}
                      checked={checked}
                      onChange={handleChange}
                      name={x.value}
                    />
                  }
                  label={x.key}
                />
                {hasSubAnswer && (
                  <Box sx={{flex: '1'}}>
                    <TextFieldCustom
                      size="small"
                      //required
                      maxLength={255}
                      sx={{minWidth: '10px'}}
                      id={x.id}
                      answers={props.answers}
                      setAnswers={props.setAnswers}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
}
