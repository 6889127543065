import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function RadioButtonsGroup(props: any) {
  const labelId = `$radiogroup-label-{props.id}`;

  return (
    <FormControl>
      <FormLabel id={labelId} focused={false} sx={{fontWeight: 'bold', color: '#323232'}}>
        {props.questionText}
        {props.required && <span>*</span>}
      </FormLabel>
      <RadioGroup
        aria-labelledby={labelId}
        row={props.inline}
        value={props.answers[props.id] ?? ''}
        name={`${props.id}-radiogroup`}
      >
        {props.choices.map((x: any, i: number) => (
          <FormControlLabel
            key={i}
            value={x.value}
            control={<Radio required={props.required} />}
            label={x.key}
            onChange={(e: any) => {
              const k: string = props?.id;
              props.setAnswers({...props.answers, [k]: e?.target?.value});
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
