import {Button, Paper, Typography} from '@mui/material';
import {useNavigate} from 'react-router';

const SurveyFinishedMessage = ({survey}: {survey: any}) => {
  const navigate = useNavigate();
  return (
    <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}, textAlign: 'center'}}>
      <Typography variant="h6" sx={{m: 2}} align="center">
        {survey.completeHtml || 'Thank you for your feedback!'}
      </Typography>
      <Button
        onClick={() => {
          navigate('/');
        }}
        variant="contained"
      >
        Back to Home
      </Button>
    </Paper>
  );
};

export default SurveyFinishedMessage;
