import {Grid, Typography} from '@mui/material';
import CheckboxGroup from './CheckBoxGroup';
import RadioButtonsGroup from './RadioButtonsGroup';
import TenantAutoComplete from './TenantAutoComplete';
import TextFieldCustom from './TextFieldCustom';
import YesNoCheckbox from './YesNoCheckbox';
import YesNoInput from './YesNoRadio';

export const FULL_WIDTH_GRID_PROPS = {xs: 12};

type Props = {
  answers: any;
  localAnswers: any;
  survey: any;
  setAnswers: (answers: any) => void;
  setLocalAnswers: (answers: any) => void;
  questions: any[];
};

const PageRender = ({answers, localAnswers, setLocalAnswers, setAnswers,survey, questions}: Props) => {
  const renderQuestion = (question: any) => {
    const value = localAnswers[question.id];
    const required = question.isRequired?.(answers) || question.required || false;
    switch (question.type) {
      case 'multiChoice':
        return (
          <CheckboxGroup
            {...question}
            required={required}
            answers={answers}
            setAnswers={setAnswers}
          />
        );
      case 'singleChoice':
        return <RadioButtonsGroup {...question} answers={answers} setAnswers={setAnswers} />;
      case 'YesNo':
        return <YesNoInput {...question} answers={answers} setAnswers={setAnswers} />;
      case 'YesNoCheckbox':
        return <YesNoCheckbox {...question} answers={answers} setAnswers={setAnswers} />;
      case 'TenantSelect':
        return (
          <TenantAutoComplete
            answers={answers}
            id={question.id}
            required={question.required}
            label={question.questionText}
            setAnswers={setAnswers}
            survey={survey}
            setValue={(newValue) => {
              setLocalAnswers({...answers, [question.id]: newValue});
            }}
            value={value || null}
          />
        );
      case 'info':
        return (
          <Typography variant="body1" sx={{fontWeight: 'bold'}}>
            {question.text}
          </Typography>
        );
      case 'shortText':
      case 'email':
      case 'longText':
        return (
          <TextFieldCustom
            {...question}
            fullWidth={question.fullWidth === undefined ? true : question.fullWidth}
            type={question.inputType ?? 'text'}
            maxLength={255}
            label={question.questionText}
            answers={answers}
            setAnswers={setAnswers}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2}>
      {questions?.map((x: any, i: number) => {
        if (x.isVisible && !x.isVisible(answers)) {
          return null;
        }
        const gridItemProps = x.gridItemProps ?? FULL_WIDTH_GRID_PROPS;
        return (
          <Grid key={x.id} item {...gridItemProps} sx={{mb: 3}} style={x.gridStyle}>
            {renderQuestion(x)}{' '}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PageRender;
