import {Box, Typography} from '@mui/material';
import {isMobile} from 'react-device-detect';

type Props = {title?: string; subTitle?: string};
const SurveyHeader = ({title, subTitle}: Props) => {
  if (!title && !subTitle) {
    return null;
  }
  return (
    <Box mb={1}>
      {!!title && (
        <Typography variant={isMobile ? 'h6' : 'h4'} align="center">
          {title}
        </Typography>
      )}
      {!!subTitle && (
        <Typography variant="subtitle2" align="center">
          {subTitle}
        </Typography>
      )}
    </Box>
  );
};

export default SurveyHeader;
