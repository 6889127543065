import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function YesNoRadio(props: any) {
  const labelId = `$radiogroup-label-{props.id}`;

  return (
    <FormControl>
      <FormLabel id={labelId} focused={false} sx={{fontWeight: 'bold', color: '#323232'}}>
        {props.questionText}
        {props.required && <span>*</span>}
      </FormLabel>
      <RadioGroup
        aria-labelledby={labelId}
        row={props.inline}
        value={props.answers[props.id] ?? ''}
        name={`${props.id}-radiogroup`}
      >
        <FormControlLabel
          value={true}
          control={<Radio required={props.required} />}
          label={'Yes'}
          onChange={(e: any) => {
            const k: string = props?.id;
            props.setAnswers((answers: any) => ({...answers, [k]: true}));
          }}
        />
        <FormControlLabel
          value={false}
          control={<Radio required={props.required} />}
          label={'No'}
          onChange={(e: any) => {
            const k: string = props?.id;
            props.setAnswers((answers: any) => ({...answers, [k]: false}));
          }}
        />
      </RadioGroup>
    </FormControl>
  );
}
