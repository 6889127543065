import {Button} from '@mui/material';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {allSurveys} from './surveydata';
import SurveyPage from './SurveyPage';
import {SurveyType} from './SurveyType';

const SurveyLanding = (props: any) => {
  const params = useParams();
  const id: number = parseInt(params?.id ?? '');

  const state: SurveyType = allSurveys.find(
    (x: SurveyType) => x.id === id || x.name == params?.id
  ) as SurveyType;

  return state ? (
    <SurveyPage survey={state} />
  ) : (
    <>
      <Link
        to={{
          pathname: `/`,
        }}
      >
        <Button>Survey not found, click Here to go back</Button>
      </Link>
    </>
  );
};

export default SurveyLanding;
